import React from "react"
import { Global, css, jsx } from "@emotion/core"
import Layout from "../components/layout"

export default () => {
  return (
    <>
      <Layout></Layout>
    </>
  )
}
